import React from 'react';
import ReactDOM from 'react-dom';
import { Browser, loadRouteModules } from '@speedy-js/universal/components';
import type { SpeedySSRData } from '@speedy-js/universal/interface';

async function hydrate() {
  const data = JSON.parse(document.getElementById('__SPEEDY_SSR_DATA__')?.textContent ?? '{}') as SpeedySSRData;

  window.__SPEEDY_SSR_DATA__ = data;

  const { assetsMeta, basename } = window.__SPEEDY_SSR_DATA__;
  const pagePath = window.location.pathname.replace(basename, '').replace(/^\//, '');
  const pageName = pagePath === '' ? 'index' : pagePath;
  console.log('Start loading page data', pageName);
  const { assets, module } = assetsMeta[pageName];
  const routeModule = await loadRouteModules(assets, module, pageName);
  console.log('Finished loading page data', pageName);
  window.__SPEEDY_SSR_DATA__.routeModules[pageName] = routeModule;

  ReactDOM.hydrate(<Browser />, document.getElementById('root'));
}

async function render() {
  ReactDOM.render(<Browser />, document.getElementById('root'));
}

function shouldDowngrade(): boolean {
  if (document.getElementById('__SPEEDY_SSR_DATA__')) return false;
  return true;
}

async function init() {
  if (shouldDowngrade()) {
    await render();
  } else {
    await hydrate();
  }
  window.__SPEEDY_APP_LOADED__ = true;
}

init();
