import React from 'react';

export const ClientOnly = (props: { children?: React.ReactNode }) => {
  const [isClient, setIsClient] = React.useState(false);

  React.useEffect(() => {
    setIsClient(true);
  }, []);

  return isClient ? <>{props.children}</> : <></>;
};
