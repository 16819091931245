import { AssetsMeta } from '../../interface';
import { getAssetUrl } from '../../helpers/getAssetUrl';

/* c8 ignore start */
export async function loadServerData(url: URL, pageName: string): Promise<Record<any, any>> {
  // TODO: only a param is very tricky
  url.searchParams.set('__data', pageName);
  const resp = await fetch(url.href);

  return resp.json();
}

export async function loadRouteModules(
  assets: string[],
  module: string,
  pageName: string
): Promise<React.ComponentType> {
  assets.forEach((asset) => {
    const importAssetPath = getAssetUrl(asset);
    if (document.querySelector(`link[href="${importAssetPath}"]`)) {
      return;
    }

    const link = document.createElement('link');
    link.rel = 'stylesheet';

    const isCss = asset.endsWith('.css');

    if (!isCss) {
      link.as = 'script';
      // @ts-ignore
      link.rel = typeof System === 'undefined' ? 'modulepreload' : 'preload';
    }
    link.href = importAssetPath;
    document.head.appendChild(link);
  });
  const url = getAssetUrl(module);
  // @ts-ignore
  const mod = await (typeof System === 'undefined' ? import(url) : System.import(url));

  // if it is client-side downgrade rendering
  // load and execute the JS file in the browser
  if (!window.__SPEEDY_SSR_DATA__) {
    window.__SPEEDY_CLIENT_DATA__.getServerDataByPage[pageName] = mod.getServerSideProps;
  }

  return mod.default;
}

export async function loadServerDataAndRouteModules({
  url,
  pageName,
  assetsMeta,
}: {
  url: URL;
  pageName: string;
  assetsMeta: AssetsMeta;
}) {
  const { assets, module } = assetsMeta[pageName];
  let serverData: Record<any, any>;
  let mod: React.ComponentType<{}>;
  if (window.__SPEEDY_SSR_DATA__) {
    [serverData, mod] = await Promise.all([loadServerData(url, pageName), loadRouteModules(assets, module, pageName)]);
  } else {
    mod = await loadRouteModules(assets, module, pageName);
    serverData = await window.__SPEEDY_CLIENT_DATA__.getServerDataByPage[pageName]({
      pathname: url.pathname,
      query: url.searchParams,
    });
  }

  return [serverData, mod] as const;
}
/* c8 ignore stop */
