import { Routes, Route } from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';
import React, { ReactElement } from 'react';
import { Entry } from '../Entry';
import { ServerSideRenderContext } from '../../interface';

export interface ServerProps extends ServerSideRenderContext {
  children: ReactElement;
}

/**
 * @internal
 */
export const Server: React.FC<ServerProps> = ({ pathname, routeData, basename, pageName, children }) => {
  return (
    <StaticRouter basename={basename} location={pathname}>
      <Entry>
        <Routes>
          <Route
            path={`/${pageName === 'index' ? '' : pageName}`}
            element={React.cloneElement(children, routeData[pageName])}
          />
        </Routes>
      </Entry>
    </StaticRouter>
  );
};
