import React from 'react';

/**
 * @internal
 */
/* c8 ignore start */
export const Entry: React.FC = ({ children }) => {
  return <>{children}</>;
};
/* c8 ignore stop */
