import { BrowserRouter, useLocation, useRoutes, Location } from 'react-router-dom';
import React from 'react';
import { Entry } from '../Entry';
import { loadServerDataAndRouteModules } from './loader';
import { RouteData, RouteModules } from '../../interface';

const createRouteElement = <T,>(Component: React.ComponentType, props: T) => {
  return <Component {...props} />;
};

export interface RoutesProps {
  routeModules: RouteModules;
  routeData: RouteData;
  location: Location;
}

const Routes: React.FC<RoutesProps> = ({ routeData, routeModules, location }) => {
  const element = Object.keys(routeModules).map((key) => ({
    path: key === 'index' ? '/' : key,
    element: createRouteElement(routeModules[key], routeData[key]),
  }));

  return useRoutes(element, location);
};

/**
 * @internal
 */
export const BrowserWithRouter = () => {
  const [routeModules, setRouteModules] = React.useState(window.__SPEEDY_SSR_DATA__?.routeModules ?? {});
  const [routeData, setRouterData] = React.useState(window.__SPEEDY_SSR_DATA__?.routeData ?? {});
  const { assetsMeta } = window.__SPEEDY_MANIFEST_DATA__;
  const location = useLocation();
  // Only after the route modules of the location is loaded
  // will we switch route
  const [loadedLocation, setLoadedLocation] = React.useState<Location>(location);

  React.useEffect(() => {
    const pagePath = location.pathname.replace('/', '');
    const pageName = pagePath === '' ? 'index' : pagePath;
    if (!routeModules[pageName]) {
      loadServerDataAndRouteModules({
        url: new URL(window.location.href),
        pageName,
        assetsMeta,
      }).then(([serverData, routeModule]) => {
        setRouterData({
          ...routeData,
          [pageName]: serverData,
        });
        setRouteModules({
          ...routeModules,
          [pageName]: routeModule,
        });
        setLoadedLocation(location);
      });
    } else {
      setLoadedLocation(location);
    }
  }, [location]);

  return <Routes routeData={routeData} routeModules={routeModules} location={loadedLocation} />;
};

export const Browser: React.FC = () => {
  return (
    <BrowserRouter basename={window.__SPEEDY_MANIFEST_DATA__.ssrOptions.baseUrl}>
      <Entry>
        <BrowserWithRouter />
      </Entry>
    </BrowserRouter>
  );
};
